import React from 'react'
import css from '../assets/css.png'
import github from '../assets/github.png'
import graphql from '../assets/graphql.png'
import html from '../assets/html.png'
import javascript from '../assets/javascript.png'
import mongodb from '../assets/mongodb.png'
import mysql from '../assets/mysql.png'
import nextjs from '../assets/nextjs.png'
import node from '../assets/node.png'
import python from '../assets/python.png'
import reactImage from '../assets/react.png'
import redux from '../assets/redux.png'
import tailwind from '../assets/tailwind.png'

const Experience = () => {

const techs = [

{
    id: 1,
    src: css,
    title: 'CSS',
    style: 'shadow-blue-500'
},
{
    id: 2,
    src: github,
    title: 'GitHub',
    style: 'shadow-gray-400'
},
{
    id: 3,
    src: graphql,
    title: 'GraphQL',
    style: 'shadow-pink-400'
},
{
    id: 4,
    src: html,
    title: 'HTML',
    style: 'shadow-orange-500'
},
{
    id: 5,
    src: javascript,
    title: 'JavaScript',
    style: 'shadow-yellow-500'
},
{
    id: 6,
    src: mongodb,
    title: 'MongoDB',
    style: 'shadow-green-500'
},
{
    id: 7,
    src: mysql,
    title: 'MySQL',
    style: 'shadow-cyan-400'
},
{
    id: 8,
    src: nextjs,
    title: 'NextJS',
    style: 'shadow-white'
},
{
    id: 9,
    src: node,
    title: 'Node',
    style: 'shadow-green-400'
},
{
    id: 10,
    src: python,
    title: 'Python',
    style: 'shadow-blue-400'
},
{
    id: 11,
    src: reactImage,
    title: 'React',
    style: 'shadow-blue-600'
},
{
    id: 12,
    src: redux,
    title: 'Redux',
    style: 'shadow-purple-400'
},
{
    id: 13,
    src: tailwind,
    title: 'Tailwind',
    style: 'shadow-sky-400'
},

]


  return (
    <div name='experience' className='bg-gradient-to-b from-gray-800 to-black w-full'>
        <div className='max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white'>
            <div>
                <p className='text-4xl font-bold border-b-4 border-gray-500 p-2 inline'>Experience</p>
                <p className='py-6'>Technologies and libraries I've worked with.</p>
            </div>
                <div className='w-full grid grid-cols-3 sm:grid-cols-4 gap-8 text-center py-8 px-12 sm:px-0'>


                {
                techs.map(({id, src, title, style}) => (
                    <div key={id} className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}>
                    <img src={src} alt='' className='w-20 mx-auto' />
                    <p className='mt-4'>{title}</p>
                </div>
                ))
            }

            
               
            </div>
        </div>
    </div>
  )
}

export default Experience